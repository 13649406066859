import {useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {useAuthState} from "../../context/Auth";
import axiosInstance from "../../apis/Axios";
import Swal from "sweetalert2";
import styled from "styled-components";
import {csReply, fetchCSDetail} from "../../apis/QnA";

const QnADetailPage = () => {
    const {qnaId} = useParams();
    const [qna, setQna] = useState(null);
    const [loading, setLoading] = useState(true);
    const [text, setText] = useState('');
    const {user} = useAuthState();

    useEffect(() => {
        const fetchPostDetail = async () => {
            try {
                const postResponse = await fetchCSDetail(qnaId);
                setQna(postResponse);
            } catch (error) {
                console.error('게시글 및 댓글 가져오기 실패:', error);
            } finally {
                setLoading(false);
            }
        };

        if (user) {
            if (user.role !== 'USER' && user.role !== 'ADMIN') {
                Swal.fire({
                    icon: 'error',
                    title: '접근 불가',
                    text: '권한이 없습니다.',
                    confirmButtonText: '확인'
                }).then((result) => {
                    if (result.isConfirmed) {
                        window.location.href = '/';
                    }
                });
            }
        }

        fetchPostDetail();
    }, [qnaId, user]);

    if (!user || (user.role !== 'USER' && user.role !== 'ADMIN')) {
        return null;
    }

    if (loading) {
        return <div>Loading...</div>;
    }

    if (!qna) {
        return <div>문의글을 찾을 수 없습니다.</div>;
    }

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const formattedDate = `${year}-${month}-${day}`;
        const formattedTime = date.toLocaleTimeString('ko-KR', {hour: '2-digit', minute: '2-digit', hour12: false});
        return `${formattedDate} ${formattedTime}`;
    };

    const handleReplySubmit = async () => {
        try {
            await csReply(qna.id, text);
            setText('');
            const updatedQna = await fetchCSDetail(qna.id);
            setQna(updatedQna);
        } catch (error) {
            console.error('답변 전송 실패:', error);
        }
    }

    return (
        <DetailBox>
            <DetailHeader>{qna.title}</DetailHeader>
            <WriteHeader>
                <Writer>작성자: {qna.writer}</Writer>
                <WriteTime>작성시간: {formatDate(qna.askDate)}</WriteTime>
            </WriteHeader>
            <DivideHr/>
            <DetailBody dangerouslySetInnerHTML={{__html: qna.content}}/>
            {/*<DetailBody>{qna.content}</DetailBody>*/}
            <DivideHr/>
            {/*<CommentHeader>답변 남기기</CommentHeader>*/}
            <CommentBox>
                {user.role === 'ADMIN' ? (
                    // 1) 관리자일 때
                    qna.answer ? (
                        // 이미 답변이 있다면 => 답변만 보여줌
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <h2 style={{ paddingBottom: '20px' }}>답변</h2>
                            <h3>{qna.answer}</h3>
                        </div>
                    ) : (
                        // 아직 답변이 없다면 => 입력 칸 & 버튼
                        <>
                            <CommentInput
                                value={text}
                                onChange={(e) => setText(e.target.value)}
                                placeholder="회신할 내용을 입력 해 주세요."
                            />
                            <CommentButton onClick={handleReplySubmit}>
                                회신하기
                            </CommentButton>
                        </>
                    )
                ) : (
                    // 2) 일반 사용자일 때 => 답변 있으면 표시, 없으면 "아직 답변되지 않았습니다."
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <h2 style={{ paddingBottom: '20px' }}>답변</h2>
                        <h3>{qna.answer ? qna.answer : '아직 답변되지 않았습니다.'}</h3>
                    </div>
                )}
            </CommentBox>

        </DetailBox>
    );
};

const DetailBox = styled.div`
    width: 80%;
`;

const DetailHeader = styled.span`
    width: 100%;
    font-size: 30px;
    font-weight: bold;
    display: flex;
    justify-content: start;
    padding: 20px 0;
    margin-bottom: 20px;
    text-align: center;
`;

const EditHeader = styled.div`
    width: 100%;
    display: flex;
    justify-content: end;
`;

const EditButton = styled.button`

`;

const WriteHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const Writer = styled.span`
    flex-grow: 1; /* 나머지 공간을 모두 차지하도록 설정 */
`;

const WriteTime = styled.span`
    margin-right: 20px; /* 시각적으로 구분을 주기 위해 오른쪽에 여백을 줌 */
`;

const WatchCount = styled.span`
    /* 추가적인 스타일링이 필요하면 여기에 작성 */
`;


const DivideHr = styled.hr``;

const DetailBody = styled.div`
    width: 100%;
    padding: 20px 0;

    img {
        max-width: 100%; /* 이미지의 최대 너비를 100%로 설정 */
        height: auto; /* 높이를 자동으로 조절 */
        display: block;
        margin: 0 auto; /* 이미지를 중앙 정렬 */
    }
`;


const CommentHeader = styled.span`
    width: 100%;
    font-size: 30px;
    font-weight: bold;
    display: flex;
    justify-content: start;
    padding: 10px 0;
    //margin-bottom: 20px;
    text-align: center;
`;

const WriteComment = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap; /* 자식 요소들이 영역을 넘어가면 다음 줄로 넘김 */
`;

const Nickname = styled.span`
    margin-left: 2px;
    color: gray;
    font-weight: bold;
    flex: none; /* flex-grow, flex-shrink, flex-basis를 모두 무시하고 원래 크기를 유지 */
`;

const Comment = styled.span`
    margin-left: 5%;
    flex: 1; /* 사용 가능한 공간을 모두 차지하도록 설정 */
    white-space: normal; /* 텍스트가 자동으로 줄바꿈 되도록 설정 */
`;

const RegisterTime = styled.span`
    margin-left: auto; /* 왼쪽의 모든 여유 공간을 차지하여 오른쪽에 배치 */
    color: gray;
    font-weight: bold;
    flex: none;
`;

const CommentBox = styled.div`
    width: 100%;
    display: flex;
`;

const CommentInput = styled.textarea`
    width: 90%;
    height: 80px;
    margin-right: 10px;
    overflow-y: auto;
    resize: none;
`;

const CommentButton = styled.button`
    width: 10%;
    background-color: black;
    color: white;
    font-size: 23px;
    border-radius: 4px;
`;

export default QnADetailPage;
