    import {useEffect, useState} from "react";
    import {useAuthState} from "../../context/Auth";
    import {fetchCSList, fetchMyQnAList} from "../../apis/QnA";
    import Swal from "sweetalert2";
    import styled from "styled-components";
    import {Link, useNavigate} from "react-router-dom";
    import ButtonComponent from "../../components/common/Button";

    const QnAListUser = () => {
        const [posts, setPosts] = useState([]);
        const [loading, setLoading] = useState(false);
        const { user } = useAuthState();
        const navigate = useNavigate();

        const fetchPosts = async () => {
            setLoading(true);
            try {
                const { data } = await fetchMyQnAList(user.id);
                setPosts(data);
            } catch (error) {
                console.error('Error fetching CS list', error);
            } finally {
                setLoading(false);
            }
        };

        useEffect(() => {
            if (user) {
                if (user.role !== 'USER' && user.role !== 'ADMIN') {
                    Swal.fire({
                        icon: 'error',
                        title: '접근 불가',
                        text: '권한이 없습니다.',
                        confirmButtonText: '확인'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            window.location.href = '/';
                        }
                    });
                } else {
                    fetchPosts();
                }
            }
        }, [user]);

        if (!user || (user.role !== 'USER' && user.role !== 'ADMIN')) {
            return null;
        }

        const formatDate = (dateString) => {
            const date = new Date(dateString);
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');
            return `${year}-${month}-${day}`;
        };

        return (
            <Container className="container">
                <Title>나의 문의내역</Title>
                <ButtonComponent
                    width={"100px"}
                    text={"문의하기"}
                    onClick={() => {navigate(`/qna-write`);}}
                />
                <ListContainer>
                    {loading ? (
                        <CenteredMessage>Loading...</CenteredMessage>
                    ) : posts && posts.length > 0 ? (
                        <>
                            <ListRow style={{ textAlign: 'center' }}>
                                <Post>
                                    <PostEtc>작성일</PostEtc>
                                    <PostTitle>제목</PostTitle>
                                    <PostEtc>작성자</PostEtc>
                                </Post>
                            </ListRow>
                            {posts.map((post) => (
                                <ListRow key={post.id}>
                                    <Post>
                                        <PostEtc>{formatDate(post.askDate)}</PostEtc>
                                        <PostTitle>
                                            <StyledLink to={`/qna/detail/${post.id}`}>{post.title}</StyledLink>
                                        </PostTitle>
                                        <PostEtc>{post.writer}</PostEtc>
                                    </Post>
                                </ListRow>
                            ))}
                        </>
                    ) : (
                        <>
                            <CenteredMessage>아직 문의한 내용이 없습니다.
                            <ButtonComponent
                                text={"문의하러 가기"}
                                onClick={() => {navigate(`/qna-write`);}}
                            />
                            </CenteredMessage>
                        </>
                    )}
                </ListContainer>
            </Container>
        );

    }

    const Container = styled.div`
      width: 100%;
      max-width: 100vw;
      height: 100%;
      padding: 10px;
      display: flex;
      flex-direction: column;
    `;
    const Title = styled.div`
      width: 100%;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid black;
      font-weight: bold;
    `;


    const ListContainer = styled.div`
      width: 100%;
      margin: 5px 0;
      display: flex;
      flex-direction: column;
    `;

    // Row
    const ListRow = styled.div`
      width: 100%;
      padding: 10px;
      border-top: 1px solid black;
    `;

    const Post = styled.div`
      width: 100%;
      height: 40px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 10px;
    `;
    const PostEtc = styled.div`
      min-width: 100px;
      display: flex;
      align-items: center;
      font-size: 12px;
    `;
    const PostTitle = styled.div`
      flex: 2;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-weight: bold;
      text-align: start;
    `;
    const StyledLink = styled(Link)`
      color: inherit;
      text-decoration: none;
    
      &:hover {
        text-decoration: underline;
      }
    `;
    const CenteredMessage = styled.div`
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 200px;
        font-size: 18px;
        color: #666;
    `;
    export default QnAListUser;
