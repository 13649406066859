import {useEffect, useState} from "react";
import Swal from "sweetalert2";
import {useAuthState} from "../../context/Auth";
import {WritePost} from "../../apis/BBS";
import styled from "styled-components";
import InputComponent from "../../components/common/Input";
import Editor from "../../components/common/Editor";
import ButtonComponent from "../../components/common/Button";
import {useNavigate} from "react-router-dom";
import {writeQnA} from "../../apis/QnA";

const QnaWritePage = () => {
    const { user } = useAuthState();
    const navigate = useNavigate();
    const [title, setTitle] = useState("");
    const [content, setContent] = useState("");
    const [imageUrls, setImageUrls] = useState([]);

    useEffect(() => {
        if (user && (user.role !== 'USER' && user.role !== 'ADMIN')) {
            Swal.fire({
                icon: 'error',
                title: '접근 불가',
                text: '권한이 없습니다.',
            }).then(() => {
                window.location.href = '/';
            });
        }
    }, [user]);

    if (!user) {
        return <div>Loading...</div>; // 유저 정보를 불러오는 동안 로딩 상태를 표시합니다.
    }

    if (user.role !== 'USER' && user.role !== 'ADMIN') {
        return null;
    }

    const handleValueChange = (setter) => (e) => {
        setter(e.target.value);
    };

    const handleSubmit = async () => {
        if (title.length === 0) {
            Swal.fire({
                icon: "error",
                title: "에러",
                text: "제목을 작성해 주세요"
            });
            return;
        } else if (content.length === 0) {
            Swal.fire({
                icon: "error",
                title: "에러",
                text: "내용을 작성해 주세요"
            });
            return;
        }

        await writeQnA(user.id, title, content, navigate);
    };

    return (
        <div>
            <WriteHeader>고객센터에 문의하기</WriteHeader>
            <TextWrite>텔레그램,디스코드 알려주시면 더 빠른답변이 가능합니다.</TextWrite>
            <hr />

            <TitleRow>
                <TitleText>제목</TitleText>
                <InputComponent
                    width={"100%"}
                    height={"25px"}
                    value={title}
                    onChange={handleValueChange(setTitle)}
                />
            </TitleRow>
            <hr />

            <Editor value={content} onChange={(value) => setContent(value)} setImageUrls={setImageUrls} type={"cs"}/>

            <ButtonRow>
                <ButtonComponent
                    height={"50px"}
                    text={"취소"}
                    background={"white"}
                    fontsize={"20px"}
                    onClick={() => navigate(`/myqna`)}
                />
                <ButtonComponent
                    height={"50px"}
                    text={"확인"}
                    background={"black"}
                    color={"white"}
                    fontsize={"20px"}
                    onClick={handleSubmit}
                />
            </ButtonRow>
        </div>
    );
}

const WriteHeader = styled.span`
    width: 100%;
    font-size: 40px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 0;
    margin-bottom: 20px;
    text-align: center;
`;

const TextWrite = styled.span`
    font-weight: bold;
    font-size: 25px;
`;

const TitleRow = styled.div`
    display: flex;
    align-items: center; // 세로 중앙 정렬
`;

const TitleText = styled.span`
    width: auto; // 너비 자동 조절
    flex-shrink: 0; // 크기 축소 방지
    margin-right: 20px; // 오른쪽 여백 설정
`;

const ButtonRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 100px;
`;

export default QnaWritePage;
