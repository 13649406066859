import axiosInstance from "./Axios";
import Swal from "sweetalert2";
import {useNavigate} from "react-router-dom";

export const registerCS = async (email, title, content, onClose) => {
    if (!email) {
        Swal.fire({
            title: "경고",
            text: "로그인 후 문의를 남겨주세요.",
            icon: "warning",
        });
        return;
    }

    try {
        const response = await axiosInstance.post('/cs/request', {email, title, content});
        Swal.fire({
            title: "전송완료",
            text: "성공적으로 문의를 전송하였습니다.\n관리자가 확인 후 가입하신 메일로 답변드리겠습니다.",
            icon: "success",
        }).then(() => {
            onClose();
        });
    } catch (error) {
        Swal.fire({
            title: "전송 실패",
            text: "문의 전송 중 문제가 발생하였습니다. 다시 시도해주세요.",
            icon: "error",
        });
    }
}

export const writeQnA = async (userId, title, content, navigate) => {
    if (!userId) {
        Swal.fire({
            title: "경고",
            text: "로그인 후 문의를 남겨주세요.",
            icon: "warning",
        });
        return;
    }

    try {
        const response = await axiosInstance.post('/cs/v2/request', {
            userId, title, content
        });
        console.log(response);
        Swal.fire({
            title: "전송완료",
            text: "성공적으로 문의를 전송하였습니다.",
            icon: "success",
        }).then(() => {
            navigate('/myqna')
        });
    } catch (error) {
        Swal.fire({
            title: "전송 실패",
            text: "문의 전송 중 문제가 발생하였습니다. 다시 시도해주세요.",
            icon: "error",
        });
        throw error;
    }
}

export const fetchCSList = async (pageIndex = 0, pageSize = 10) => {
    try {
        const response = await axiosInstance.get('/cs/list', {
            params: {
                pageIndex,
                pageSize,
            },
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching CS list:', error);
        throw error;
    }
};

export const fetchMyQnAList = async (userId) => {
    try {
        const response = await axiosInstance.get(`/cs/list/${userId}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching CS list:', error);
        throw error;
    }
}

export const fetchCSDetail = async (id) => {
    try {
        const response = await axiosInstance.get(`/cs/detail/${id}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching CS detail:', error);
        throw error;
    }
};

export const csReply = async (qnaId, comment) => {
    try {
        const response = await axiosInstance.post('/cs/write-reply', {csId: qnaId, comment});
        Swal.fire({
            title: "전송완료",
            text: "성공적으로 답글을 남겼습니다.",
            icon: "success",
        });
    } catch (error) {
        Swal.fire({
            title: "전송 실패",
            text: "답글 작성 중 문제가 발생하였습니다. 다시 시도해주세요.",
            icon: "error",
        });
        console.error('Error sending reply:', error);
    }
};
